/* Global CSS */

html{
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
:root {
  --primary: #fffb00;
  --heading-color: #ffffff;
  --bg-shade: #000000;
  --github: #e62872;
  --darkblue: #ffffff;
  --black: #000000;
  --white: #ffffff;
  --darkgrey: #0c0c0c;
}
.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--black);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--black);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--black);
  background-color: var(--primary);
  border: 2px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--black);
}
.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  color: var(--white);
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  color: var(--white);
}
/* Heading 3 */
h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills & About Me */
.section--title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
.skills-section--heading{
  font-size: 46px;
  line-height: 1.2;
}
/* Portfolio, Testimonial & Contact Me */
.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  background: var(--black);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding-left: 85.3px;
  padding-right: 85.3px;
  padding-top: 15px;
  padding-bottom: 15px;
  z-index: 999;
}
.navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.navbar--items ul > li > a {
  text-decoration: none;
}
/* Navbar Content */
.navbar--content {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}
.navbar--active-content {
  color: var(--primary);
}
/* Navbar Styler Ends */

/* Hero Section Style */
.hero--section {
  position: relative; /* Add this to make the pseudo-element position relative to this div */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 85.333px 133.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-image: url("/public/img/Topographic Dark.jpg");
  background-size: cover;
  background-position: center;
  background-color: var(--bg-shade);
  margin-top: -50px;
  height: 80vh;
}

.hero--section::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1.0));
  z-index: 1; /* Adjust the z-index to make sure the gradient overlay is below the content */
}



.hero--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
}
.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.project--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.project--section--content--box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
}
.project--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.project-section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}

.hero--section--content--box > button {
  margin-top: 21.333px;
}
.hero--section--title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}
.hero--section--title--color {
  color: var(--primary);
}
.hero--section-description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
}
.about--section-description {
  color: var(--darkblue);
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
  align-items: center;
}
.hero--section--img {
  display: flex;
  z-index: 2;
}
.hero--section--img > img {
  width: 100%;
  height: 100%;
}
/* Hero Section Style Ends */

/* Skills Section Style */
.skills--section {
  display: flex;
  padding-left: 85.3px;
  padding-right: 85.3px;
  padding-top: 12%;
  padding-bottom: 12%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--black);
}
.skills--section--heading {
  color: var(--heading-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
}
.skills--section--container {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);
}
.skills--section--card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--darkgrey);
  min-height: 300px;
  max-height: 400px;
  min-width: 25%;
  
}

.technology-list{
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}

.technology-item{
  color: var(--black);
}
.skills--section--card:hover {
  border-bottom: 4px solid var(--primary);
}
.skills--section--card:hover .skills--section--description {
  color: var(--darkblue);
}
.skills--section--img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.skills--section--title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills--section--description {
  color: var(--white);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
/* Skills Style Ends */

/* About Us */
.about--section {
  display: grid;
  padding-top: 4%;
  padding-bottom: 12%;
  padding-left: 85.3px;
  padding-right: 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--black);
}
.rrproject--section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8%;
  padding-bottom: 12%;
  padding-left: 85.3px;
  padding-right: 85.3px;
  align-items: center;
  gap: 114.6px;
  justify-content: center;
  background-color: var(--black);
}
.mlproject--section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 8%;
  padding-bottom: 12%;
  padding-left: 85.3px;
  padding-right: 85.3px;
  align-items: center;
  gap: 114.6px;
  justify-content: center;
  background-color: var(--black);
}
.about--section--img > img {
  max-width: 100%;
  align-content: center;
  border-left: none;
  border-top: none;
  border-radius: 30px;
}


.rrproject--section--img > img {
  
  align-content: center;
  border-left: none;
  border-top: none;
  border-radius: 30px;
}
/* About Us Ends */
.project-section-1{
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 1400px;
}
.project-section-2{
  display: flex;
  flex-direction: row;
  gap: 80px;
  width: 1400px;
}
/* My Portfolio Starts */
.portfolio--section {
  display: flex;
  padding: 133.3px 85.3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--black);
}
.portfolio--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}
.portfolio--section--container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-evenly;
  gap: 30px;
  width: 100%;
}
.portfolio--section--img {
 
  width: 100%;
}
.portfolio--section--img > img {
  width: 100%;
  max-width: 100px;
  border-radius: 20px;
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 0 0;
  background: var(--darkgrey);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  min-height: 400px;
  max-height: 600px;
  min-width: 80%;
  max-width: 100%;
}

.portfolio--section--card:hover {
  border-bottom: 4px solid var(--primary);
}

.full--card--link{
  display: flex;
  align-items: stretch;
  text-decoration: none;
}

a:link{
  text-decoration: none;
}
a:visited{
  text-decoration: none;
}
a:hover{
  text-decoration: none;
}
a:active{
  text-decoration: none;
}

.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.portfolio--section--title {
  color: var(--heading-color);
  font-size: 24px;
  margin-bottom: 10px;
}
.section--heading {
  font-size: 56px;
}
.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
  color: var(--white);
}
.portfolio--link:hover {
  color:#fffb00;
}
.portfolio--link:hover path {
  stroke: var(--primary);
}

.rrproject--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-left: none;
  border-top: none;
}

.rrproject--section--img > img {
  border-radius: 30px;
  width: 400px;
}

.rrimg {
  max-width: 325px;
}

.mlproject--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border-left: none;
  border-top: none;
}

.mlproject--section--img > img {
  border-radius: 20px;
  width: 600px;
}

.mlimg {
  max-width: 800px;
}

.back-button-container{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

}
/* Portfolio Ends */

.contact--section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 80px;
  background-color: var(--black);
}
.contact--container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 50%;
}
.contact--form--container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact--label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact--input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
.contact-icon-link:hover{
  color:#0c0c0c;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox--label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer--container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: center;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer--text{
  color: var(--white);
  text-align: center;
  font-size: 0.8em;
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer--items ul > li > a {
  text-decoration: none;
}
.footer--social--icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 106px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer--content--container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}

/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero--section--title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills--section--container {
    gap: 16px;
  }
  .skills--section--card {
    gap: 28px;
  }
  .skills--section--card--content {
    gap: 20px;
  }
  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  .project-section-1{
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .project-section-2{
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }
  .project-section-description {
    font-size: 20px;
    line-height: 1.5;
  }
  .btn-outline-primary {
    display: none;
  }
  .hero--section {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 50px;
    padding-top: 12%;
    padding-bottom: 20%;
    padding-left: 4%;
    padding-right: 4%;
    height: fit-content;
  }
  .hero--section--title,
  .hero--section-description,
  .footer--content {
    text-align: center;
  }

  .about--section-description{
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    padding-top: 25px;
  }

  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .rrproject--section,
  .mlproject--section,
  .footer--link--container,
  .footer--items > ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }

  .portfolio--section{
    padding-top: 16%;
    padding-left: 4%;
    padding-right: 4%;
    padding-bottom: 16%;
  }

  .skills--section,
  .portfolio--container,
  .hero--section--content,
  .hero--section--content--box {
    align-items: center;
    max-width: 100%;
    overflow: hidden;
    gap: 0px;
  }
  .portfolio--container-box {
    gap: 30px;
  }
  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .contact--container{
    max-width: 100%;
  }
  .about--section {
    gap: 20px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 12%;
    padding-bottom: 16%;
  }
  .rrproject--section {
    gap: 40px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 12%;
    padding-bottom: 16%;
    padding-top: 100px;
  }
  .mlproject--section {
    gap: 40px;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 12%;
    padding-bottom: 16%;
    padding-top: 100px;
  }
 
  .hero--section--title,
  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 25px;
  }
  .hero--section--content--box {
    gap: 10px;
  }
  .contact--form--container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer--social--icon > ul,
  .footer--items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }
}

/* Hamburger menu  */

.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}





.project--section--content--box {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.project--section--content--box:nth-child(odd) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1200px) {
  .project-section-1{
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
    width: 100%;
  }
  .rrproject--section--img > img{
    max-width: 350px;
  }
  .mlproject--section--img > img{
    max-width: 350px;
  }
  .project-section-2{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
  }
  .skills-section--heading{
    font-size: 36px;
  }
  .navbar{
    padding: 15px;
  }
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar--items {
    display: flex;
  }
  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(0, 0, 0);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }
  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar--items ul li {
    text-align: center;
  }
  .navbar--items ul li a {
    padding: 0.5rem;
  }
  .navbar--items {
    z-index: -1000;
  }
  .navbar--items.active {
    top: 30px;
  }
  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

/* Responsive Screens Ends*/
