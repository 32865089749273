.video-responsive {
  width: 100%;
  max-height: 800px;
  padding-bottom: 56.25%; /* (9/16)*100 */
  position: relative;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
